@import url(https://fonts.google.com/specimen/Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.professional-info {
  display: none;
  opacity: 0;
}
input[type="checkbox"]:checked .professional-info {
  display: block;
  opacity: 1;
}
.brand-link label {
  display: none;
  height: 100px;
}
.brand-link:hover label {
  display: inline;
}

.categorie-list li ul {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.inactiveCat ul {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.activeCat > a {
  color: #024dbc;
}
.activeCat ul {
  display: inline;
  visibility: visible;
  opacity: 1;
}
.inactiveCat:hover ul {
  display: inline;
  visibility: visible;
  opacity: 1;
  background: #f2f4f9;
}

.activePage {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  border-radius: 100%;
  margin: 0 2px;
  background-color: #163c74;
  color: #ffffff;
}

.best-categorie{
    max-height: 80px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.best-categorie-ul{
    list-style: none;
    height: 100%;
    display: flex;
    -webkit-animation: 4s linear 4s infinite alternate slidein;
            animation: 4s linear 4s infinite alternate slidein;
}

.best-categorie-ul:hover{
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
@-webkit-keyframes slidein {
    from { -webkit-transform: translateX(0px); transform: translateX(0px); }
    to   { -webkit-transform: translateX(-600px); transform: translateX(-600px); }
}
@keyframes slidein {
    from { -webkit-transform: translateX(0px); transform: translateX(0px); }
    to   { -webkit-transform: translateX(-600px); transform: translateX(-600px); }
}

.best-categorie::-webkit-scrollbar{
    width: 0;
}
.best-categorie .item{
    min-width: 200px;
    height: 120px;
    line-height: 120px ;
    text-align: center;
}
.main-btn {
  background: #edf4fe;
  border-radius: 26px;
  display: inline-block;
  font-size: 15px;
  padding: 12px 41px;
  font-weight: 700;
  color: #163c74;
  border: none;
}
.main-btn:hover {
  background-color: #163c74;
  color: #ffffff;
}

.custom-container-sb {
  display: flex;
  height: 140px;
  justify-content: center;
  align-items: center;
}

.btn-bg {
  color: #fff;
  background: #163c74;
  font-weight: 600;
  height: 48px;
  border-radius: 26px;
}
.btn-bg:hover {
  color: #fff;
}

#AbonModel {
  height: 150px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.subs input[type="email"] {
  border-radius: 26px;
  box-shadow: 0px 0px 6px 6px #ccc;
}

.bg-color {
  background-color: #f5f5f5;
}
.title {
  margin: 0.9rem;
}
.title h2 {
  color: #2f4e81;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
}

.title h4 {
  color: red;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.one-prod {
  width: 300px;
  background-color: #dadada;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.btn-icon {
  color: white;
  border: none;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 10%;
  background-color: #2f4e81;
  text-align: center;
  box-shadow: 0 0 7px rgb(0 0 0 / 9%);
  margin-right: 7px;
  position: relative;
}
.btn-icon:hover {
  background-color: #0E9B4A;
}

@media print {
    /* @page {size: landscape} */
    @page {
        size: A4;
    }
    table {
        max-width: 2480px !important;
        width: 100% !important;
    }
    table tr td, table tr th {
        width: auto !important;
        overflow: hidden !important;
        word-wrap: break-word !important;
    }
    html, body {
        width: 100%;
        height: 100%;
    }
    .buttons {
        display: none !important;
    }
    .col-md-4 {
        width: 40% !important;
        /* display: flex; */
    }

    .invoive-info{
        margin: 20px 0 0 20px;
    }

    .hfc-info{
        margin-left: 20px;
    }
}
.pro-inf {
  display: none;
  opacity: 0;
  transition: all 0.3s linear;
}

.is-visible {
  display: block;
}

.is-open {
  margin-top: 15px;
  opacity: 1;
}
.slide-up {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.hid-box {
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.show-box {
  transition: visibility 0s, opacity 0.5s linear;
  transition-duration: 0.5s;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.wrapper{
  
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:start;
    margin-left:20px;
    margin-bottom:10px;
}

.wrapper .file-upload{

    height:50px;
    width:50px;
    border-radius: 100px;
    position:relative;
    
    display:flex;
    justify-content:center;
    align-items: center;  

    border:4px solid #FFFFFF;
    overflow:hidden;
    background-image: linear-gradient(to bottom, #2590EB 50%, #FFFFFF 50%);
    background-size: 100% 200%;
    transition: all 1s;
    color: #ffffff;
    font-size:30px;

}

.wrapper .file-upload input[type='file']{

    height:50px;
    width:50px;
    position:absolute;
    top:0;
    left:0;
    opacity:0;
    cursor:pointer;

  }


