.wrapper{
  
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:start;
    margin-left:20px;
    margin-bottom:10px;
}

.wrapper .file-upload{

    height:50px;
    width:50px;
    border-radius: 100px;
    position:relative;
    
    display:flex;
    justify-content:center;
    align-items: center;  

    border:4px solid #FFFFFF;
    overflow:hidden;
    background-image: linear-gradient(to bottom, #2590EB 50%, #FFFFFF 50%);
    background-size: 100% 200%;
    transition: all 1s;
    color: #ffffff;
    font-size:30px;

}

.wrapper .file-upload input[type='file']{

    height:50px;
    width:50px;
    position:absolute;
    top:0;
    left:0;
    opacity:0;
    cursor:pointer;

  }

