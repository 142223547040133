.main-btn {
  background: #edf4fe;
  border-radius: 26px;
  display: inline-block;
  font-size: 15px;
  padding: 12px 41px;
  font-weight: 700;
  color: #163c74;
  border: none;
}
.main-btn:hover {
  background-color: #163c74;
  color: #ffffff;
}
