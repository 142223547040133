.best-categorie{
    max-height: 80px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.best-categorie-ul{
    list-style: none;
    height: 100%;
    display: flex;
    animation: 4s linear 4s infinite alternate slidein;
}

.best-categorie-ul:hover{
    animation-play-state: paused;
}
@keyframes slidein {
    from { transform: translateX(0px); }
    to   { transform: translateX(-600px); }
}

.best-categorie::-webkit-scrollbar{
    width: 0;
}
.best-categorie .item{
    min-width: 200px;
    height: 120px;
    line-height: 120px ;
    text-align: center;
}