.professional-info {
  display: none;
  opacity: 0;
}
input[type="checkbox"]:checked .professional-info {
  display: block;
  opacity: 1;
}
.brand-link label {
  display: none;
  height: 100px;
}
.brand-link:hover label {
  display: inline;
}

.categorie-list li ul {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.inactiveCat ul {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.activeCat > a {
  color: #024dbc;
}
.activeCat ul {
  display: inline;
  visibility: visible;
  opacity: 1;
}
.inactiveCat:hover ul {
  display: inline;
  visibility: visible;
  opacity: 1;
  background: #f2f4f9;
}

.activePage {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  border-radius: 100%;
  margin: 0 2px;
  background-color: #163c74;
  color: #ffffff;
}
