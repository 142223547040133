.custom-container-sb {
  display: flex;
  height: 140px;
  justify-content: center;
  align-items: center;
}

.btn-bg {
  color: #fff;
  background: #163c74;
  font-weight: 600;
  height: 48px;
  border-radius: 26px;
}
.btn-bg:hover {
  color: #fff;
}

#AbonModel {
  height: 150px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.subs input[type="email"] {
  border-radius: 26px;
  box-shadow: 0px 0px 6px 6px #ccc;
}
