@import url("https://fonts.google.com/specimen/Roboto");
.bg-color {
  background-color: #f5f5f5;
}
.title {
  margin: 0.9rem;
}
.title h2 {
  color: #2f4e81;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
}

.title h4 {
  color: red;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.one-prod {
  width: 300px;
  background-color: #dadada;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.btn-icon {
  color: white;
  border: none;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 10%;
  background-color: #2f4e81;
  text-align: center;
  -webkit-box-shadow: 0 0 7px rgb(0 0 0 / 9%);
  box-shadow: 0 0 7px rgb(0 0 0 / 9%);
  margin-right: 7px;
  position: relative;
}
.btn-icon:hover {
  background-color: #0E9B4A;
}
