.pro-inf {
  display: none;
  opacity: 0;
  transition: all 0.3s linear;
}

.is-visible {
  display: block;
}

.is-open {
  margin-top: 15px;
  opacity: 1;
}
.slide-up {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.hid-box {
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.show-box {
  transition: visibility 0s, opacity 0.5s linear;
  transition-duration: 0.5s;
  height: 100%;
  visibility: visible;
  opacity: 1;
}
