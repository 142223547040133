@media print {
    /* @page {size: landscape} */
    @page {
        size: A4;
    }
    table {
        max-width: 2480px !important;
        width: 100% !important;
    }
    table tr td, table tr th {
        width: auto !important;
        overflow: hidden !important;
        word-wrap: break-word !important;
    }
    html, body {
        width: 100%;
        height: 100%;
    }
    .buttons {
        display: none !important;
    }
    .col-md-4 {
        width: 40% !important;
        /* display: flex; */
    }

    .invoive-info{
        margin: 20px 0 0 20px;
    }

    .hfc-info{
        margin-left: 20px;
    }
}